import React, { Component } from "react";

export default class ToDoHeader extends Component {
  render() {
    return (
      <thead>
        <tr>
          <th>Tasks</th>
          <th>Actions</th>
        </tr>
      </thead>
    );
  }
}
